import { useState, useEffect } from "react";

export default function ClassList({
  classList,
  handleSelectedClass,
  classInfo,
}) {
  // 是否收合
  const [isOpen, setIsOpen] = useState(false);
  // 選中的年級
  const [selectedGrade, setSelectedGrade] = useState("所有年級");
  // 錯誤提示訊息
  const [errMsg, setErrMsg] = useState("");

  // 提取所有年級，但是不包含gradeYear為空值
  const uniqueGrades = [
    ...new Set(
      classList
        .map((v) => v.gradeYear)
        .filter((gradeYear) => gradeYear !== null && gradeYear !== undefined)
    ),
  ];

  // 依選擇的年級篩選班級列表
  const filteredClassList =
    selectedGrade === "所有年級"
      ? classList
      : classList.filter((v) => v.gradeYear === parseInt(selectedGrade));

  useEffect(() => {
    // 接收班級清單後彈出選單
    if (classList.length > 0) {
      setIsOpen(true);
    }
  }, [classList]);

  // 切換選擇的年級
  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
  };

  const handleClassClick = (classID) => {
    setIsOpen(false);
    handleSelectedClass(classID);
  };

  // 點擊收合按鈕
  const handleBtnClicked = () => {
    if (!isOpen) {
      setIsOpen(true);
      setErrMsg("");
    } else if (classInfo?.classID) {
      setIsOpen(false);
      setErrMsg("");
    } else {
      // 尚未選擇顯示任何班級
      setErrMsg("尚未選擇班級");
    }
  };

  return (
    <>
      <div className="text-right relative">
        {/* 展開/收合按鈕 */}
        <button
          className="bg-base-200 drop-shadow
           px-2 py-1 rounded-b-lg ms-auto absolute top-0 right-0 z-[11]"
          onClick={handleBtnClicked}
        >
          <div className="fill-stone-700">
            <div className={`w-5 h-5 ${isOpen ? "rotate-180" : ""}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </div>
          </div>
        </button>
        {isOpen && errMsg && (
          <p className="absolute z-[11] left-1/2 -translate-x-1/2 text-rose-500">
            {errMsg}
          </p>
        )}
      </div>

      <div
        className={`absolute w-full top-0 left-0 z-[10] h-full min-h-screen transition duration-500 ${
          isOpen ? "" : "-translate-y-full"
        } `}
      >
        {classList.length > 0 ? (
          <div className="p-10 bg-base-200 h-full overflow-auto scroll-box scroll-bar2">
            <div className="mb-6 text-end">
              {/* 年級選擇下拉式選單 */}
              <select
                className="select w-fit"
                onChange={handleGradeChange}
                value={selectedGrade}
              >
                <option value="所有年級">所有年級</option>
                {uniqueGrades.map((grade) => (
                  <option key={grade} value={grade}>
                    {grade}年級
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-4">
              {filteredClassList.map((v, index) => (
                <button
                  key={index}
                  className={`btn w-full bg-white ${
                    classInfo?.classID === v.classID
                      ? "!bg-main !border-base-300"
                      : ""
                  }`}
                  onClick={() => handleClassClick(v.classID)}
                >
                  {v.className}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-full bg-base-200">
            班級列表為空
          </div>
        )}
      </div>
    </>
  );
}
