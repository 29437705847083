import { useState, useEffect, useRef } from "react";
import LineChart from "./LineChart";
import MultiLineChart from "./MultiLineChart";
import Table from "./Table";
const Card2 = ({ item, nowIndex, index, handleOpen, collRef }) => {
  const [dataSet, setDataSet] = useState([]);
  const [dataLabelSet, setDataLabelSet] = useState([]);
  const animateRef = useRef(null);
  const formatSight = (key) => {
    const filteredData = item.data.map((item) =>
      item[key] ? (item[key] / 10).toFixed(1) : null
    );
    return filteredData;
  };
  useEffect(() => {
    setDataSet([]);
    setDataLabelSet([]);
    if (item.data.length > 0) {
      if (item.title === "視力") {
        setDataSet([
          formatSight("sight0_l"),
          formatSight("sight_l"),
          formatSight("sight0_r"),
          formatSight("sight_r"),
        ]);
        setDataLabelSet(item.grade_semester);
      } else if (item.title === "BMI") {
        const data1 = item.data.map((item) => item.bmi);
        setDataSet(data1);
        setDataLabelSet(item.grade_semester);
      } else {
        setDataSet(item.data);
        setDataLabelSet(item.grade_semester);
      }
    } else {
      setDataSet([]);
      setDataLabelSet([]);
    }
    animateRef.current.className = `your-updated-class`;
    setTimeout(() => {
      animateRef.current.className = `slide-in-bottom`;
    }, 40);
  }, [item.data]);

  return (
    <div className="w-full" ref={animateRef}>
      <div className="myCollapse" ref={collRef}>
        <button
          className="!p-0 !pb-2  font-medium flex gap-4 cursor-pointer w-full"
          onClick={() => {
            handleOpen(index);
          }}
        >
          <div
            className={`border-2 relative border-stone-700 rounded-2xl bg-white flex items-center gap-2 w-full p-3 ps-4 pe-11 shadowCard${
              (nowIndex + index) % 4
            }`}
          >
            <div className="rope"></div>
            <div className="collIcon absolute right-4 fill-stone-700 p-1 rounded-full bg-main">
              <div className="w-5 h-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              </div>
            </div>
            <div className="">
              <img
                src={`/images/${item.img}`}
                alt=""
                className="w-14 h-14 object-contain"
              />
            </div>
            <TitleArea item={item} dataSet={dataSet} />
          </div>
        </button>
        <div className="collBox">
          <div className="contentRope rope1"></div>
          <div className="contentRope rope2"></div>
          <div
            className={`h-[160px] max-w-full overflow-hidden border-2 border-stone-700 px-2 pt-[12px] pb-0 rounded-2xl bg-white mt-4  shadowCard${
              (nowIndex + index) % 4
            }`}
          >
            {item.title === "BMI" && (
              <div className="absolute top-1 left-5">
                <div className="dropdown dropdown-right">
                  <div
                    tabIndex={0}
                    role="button"
                    className="bg-main p-[5px] rounded-full me-1 shadow active:scale-95"
                  >
                    <div className="w-[20px] h-[20px] ">
                      <svg
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g strokeWidth={0} />
                        <g strokeLinecap="round" strokeLinejoin="round" />
                        <path
                          d="M12 21.5A9.25 9.25 0 1 0 12 3a9.25 9.25 0 0 0 0 18.5"
                          stroke="#000"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.93 8.15a.92.92 0 0 1-.93.9.9.9 0 1 1 .93-.9m-1.64 8.38v-5.38a.7.7 0 0 1 .71-.72.69.69 0 0 1 .69.72v5.38a.701.701 0 0 1-1.4 0"
                          fill="#000"
                        />
                      </svg>
                    </div>
                  </div>
                  <ul className="dropdown-content z-[1]  p-2  rounded-2xl bg-white w-20 grid gap-1 drop-shadow max-h-[160px] overflow-auto">
                    <li className="border-l-8 border-purple text-purple text-center ">
                      過輕
                    </li>
                    <li className="border-l-8 border-green text-green text-center">
                      正常
                    </li>
                    <li className="border-l-8 border-main text-main text-center">
                      過重
                    </li>
                    <li className="border-l-8 border-pink text-pink text-center">
                      肥胖
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {dataSet.length > 1 ? (
              <ChartArea
                item={item}
                dataSet={dataSet}
                dataLabelSet={dataLabelSet}
              />
            ) : (
              <div className="grid text-center items-center h-full">
                <p>尚無歷史數據</p>
              </div>
            )}
          </div>
          <div className="pb-2"></div>
        </div>
      </div>
    </div>
  );
};

const getColor = (info) => {
  switch (info) {
    case "過輕":
      return "#CDB6DB";
    case "正常":
      return "#ADD6B8";
    case "過重":
      return "#F3B283";
    default:
      return "#F2A296";
  }
};
const TitleArea = ({ item, dataSet }) => {
  if (item.data.length > 0 && dataSet.length > 0) {
    if (item.title === "視力") {
      function formatValue1(value) {
        return value !== null ? value : "";
      }
      function formatValue2(value) {
        return value !== null ? "(" + value + ")" : "";
      }
      const leftEye = formatValue1(dataSet[0][dataSet[0].length - 1]);
      const afterL = formatValue2(dataSet[1][dataSet[1].length - 1]);
      const rightEye = formatValue1(dataSet[2][dataSet[2].length - 1]);
      const afterR = formatValue2(dataSet[3][dataSet[3].length - 1]);
      const info = `左${leftEye} ${afterL}右${rightEye}${afterR}`;
      // const info = `左眼${leftEye} 右眼${rightEye}`;
      return (
        <div className="text-stone-700 grow text-left max-h-16 overflow-auto">
          <h2 className="">{item.title}</h2>
          <p className="">{info}</p>
        </div>
      );
    } else if (item.title === "BMI") {
      const info = item.data[item.data.length - 1].info;
      const color = getColor(info);
      return (
        <div className="text-stone-700 grow flex">
          <div className="text-left  max-h-16 overflow-auto">
            <h2 className="">{item.title}</h2>
            <p className="">{dataSet.slice(-1)}</p>
          </div>
          <div className="grow flex justify-center items-center max-h-16 overflow-auto">
            <div
              className={`w-fit rounded-full px-3 py-1 text-sm bg-${color.replace(
                /#/g,
                ""
              )}`}
            >
              {info}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-stone-700 grow text-left max-h-16 overflow-auto">
          <h2 className="">{item.title}</h2>
          <p className="">
            {dataSet.slice(-1)[0] + `${item.title === "身高" ? "cm" : "kg"}`}
          </p>
        </div>
      );
    }
  } else {
    return (
      <div className="text-stone-700 grow text-left max-h-12 overflow-auto">
        <div className="">{item.title}</div>
        <div className="">{"尚無數據"}</div>
      </div>
    );
  }
};

const ChartArea = ({ item, dataSet, dataLabelSet }) => {
  const [sightSide, setSightSide] = useState(false);
  switch (item.title) {
    case "視力": {
      return (
        <div className="flex w-full h-full">
          <div className="text-text flex flex-col pr-2 justify-between">
            <button
              className="flex bg-pink rounded-badge p-1 gap-1 text-xs"
              onClick={() => {
                setSightSide((prev) => !prev);
              }}
            >
              <div className={`avatar placeholder`}>
                <div
                  className={`${!sightSide && "bg-white "} rounded-full w-4`}
                >
                  左
                </div>
              </div>
              <div className={`avatar placeholder`}>
                <div className={`${sightSide && "bg-white"} rounded-full w-4`}>
                  右
                </div>
              </div>
            </button>
            <div className="flex flex-col text-sm gap-1 i">
              <div className="flex items-center gap-1">
                <div>裸</div>
                <div className="grow h-1 bg-green rounded-full" />
              </div>
              <div className="flex items-center gap-1">
                <div>矯</div>
                <div className="grow h-1 bg-purple rounded-full" />
              </div>
            </div>
            <div className="text-center">
              <div className="dropdown dropdown-right dropdown-end">
                <div
                  tabIndex={0}
                  role="button"
                  className="bg-main p-[5px] rounded-full me-1 shadow active:scale-95"
                >
                  <div className="w-[20px] h-[20px] ">
                    <svg
                      viewBox="-0.5 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g strokeWidth={0} />
                      <g strokeLinecap="round" strokeLinejoin="round" />
                      <path
                        d="M12 21.5A9.25 9.25 0 1 0 12 3a9.25 9.25 0 0 0 0 18.5"
                        stroke="#000"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.93 8.15a.92.92 0 0 1-.93.9.9.9 0 1 1 .93-.9m-1.64 8.38v-5.38a.7.7 0 0 1 .71-.72.69.69 0 0 1 .69.72v5.38a.701.701 0 0 1-1.4 0"
                        fill="#000"
                      />
                    </svg>
                  </div>
                </div>
                <div className="dropdown-content z-[1]  p-2  rounded-2xl bg-white w-40 grid gap-1 drop-shadow max-h-[150px] overflow-auto text-left text-sm">
                  <h3 className="">特殊紀錄說明</h3>
                  <p className="">-0.7：配戴塑型片</p>
                  <p className="t">-0.9：無法量測</p>
                </div>
              </div>
            </div>
          </div>
          <MultiLineChart
            dataSet={dataSet}
            labels={dataLabelSet}
            sightSide={sightSide}
          />
        </div>
        // <Table dataSet={dataSet} labels={dataLabelSet} />
      );
    }
    case "BMI": {
      const data2 = item.data.map((item) => getColor(item.info));

      return (
        <LineChart dataSet={dataSet} labels={dataLabelSet} colorArr={data2} />
      );
    }
    default: {
      return <LineChart dataSet={dataSet} labels={dataLabelSet} />;
    }
  }
};
export default Card2;
