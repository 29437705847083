import React, { useEffect, useState, createContext, useContext } from "react";
import { useQueryString } from "./use-querystring";
import { useNavigate } from "react-router-dom";
import { DevAPIJasmineHelper } from "../dal/devapi_jasmine";

export const UserInfoContext = createContext({
  userInfo: null,
  logout: async () => {},
  currentSemester: { schoolYear: undefined, semester: undefined },
});

// console.log('before UserValidation')

export default function UserValidation({ children }) {
  console.log(' user-validation ...')
  const queryString = useQueryString();
  const code = queryString.get("code");
  const dsns = queryString.get("dsns");

  // console.log({ action: 'enter UserValidation', code, dsns, role })

  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);

  /** 取得 server 的 session 中的使用者資料 */
  const getUserInfo = async () => {
    console.log(" getUserInfo() ....");
    try {
      const resp = await fetch("/service/user/info");
      const data = await resp.json();
      console.log({ action: "getUserInfo()", data });
      if (data.errCode) {
        setUserInfo(null);
        navigate("/auth/not-signin");
      } else {
        setUserInfo(data);
        if (data.roleType === "student" || data.roleType === "parent") {
          navigate("/main");
        } else if (data.roleType === "teacher") {
          // 取得導師班資料
          const classes = await DevAPIJasmineHelper.getHomeroomClasses(data.schoolDsns);
          data.classes = classes ;
          setUserInfo({...data});
          navigate("/main"); 
        } else {
          throw { error: 'unauthorized!' }
        }
      }
    } catch (ex) {
      //
      navigate("auth/not-signin");
    }
  };

  /** 由 web 或 app 開啟時呼叫 */
  const validateIdentityCode = async (code, dsns) => {
    console.log({ action: "validateIdentityCode()", code, dsns });
    try {
      // return ;
      const resp = await fetch(
        `/oauth/callback/identity_code?code=${code}&dsns=${dsns}`
      );

      const data = await resp.json();
      console.log({ action: "validateIdentityCode", data });
      setUserInfo(data);

      getUserInfo();  // 重新從 server 取得使用者身份。
    } catch (ex) {
      console.log({ ex });
      navigate("auth/not-signin");
    }
  };

  /** 登出 */
  const signout = async () => {
    console.log("signout in user-validation ");
    await fetch("/auth/logout");
    setUserInfo(null);
    navigate("auth/not-signin");
  };

  useEffect(() => {
    console.log(" trigger useEffect() in UserValidation ");

    if (code && dsns) {
      validateIdentityCode(code, dsns);
    } else {
      getUserInfo();
    }
  }, []);

  return (
    <UserInfoContext.Provider
      value={{
        userInfo,
        logout: signout,
        currentSemester: {
          schoolYear: userInfo?.schoolYear,
          semester: userInfo?.semester,
        },
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
}


export function useUserContext() {
  return useContext(UserInfoContext);
}
