
/** 負責呼叫 devapi 的 jasmine 以取得校務群組資料 */
export class UserHelper {

    /** 取得指定身分證雜湊馬的學生的性別 */
    static async getGender(idno_hash) {
        const path = `/service/user/getGender?idno_hash=${idno_hash}`
        const resp = await fetch(path)
        const data = await resp.json();
        return data;
    }

    /** 取得指定登入者的身份資料 */
    static async getInfo() {
        const path = `/service/user/info`
        const resp = await fetch(path)
        const data = await resp.json();
        return data;
    }
}

