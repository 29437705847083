
/** 負責呼叫 service 取得健康資料  */
export class HealthHelper {

    /** 取得登入者的身高體重 */
    static async getMyWH() {
        const path = '/service/health/get_wh'
        const resp = await fetch(path)
        const wh = await resp.json();
        return wh;
    }

    /** 取得登入者的視力紀錄 */
    static async getMySights() {
        const path = '/service/health/get_sights'
        const resp = await fetch(path)
        const sights = await resp.json();
        return sights;
    }

    /** 取得登入者的身高體重 */
    static async getStudentWH(idno_hash) {
        const path = `/service/health/get_stud_wh?idno_hash=${idno_hash}`
        const resp = await fetch(path)
        const wh = await resp.json();
        return wh;
    }

    /** 取得登入者的視力紀錄 */
    static async getStudentSights(idno_hash) {
        const path = `/service/health/get_stud_sights?idno_hash=${idno_hash}`
        const resp = await fetch(path)
        const sights = await resp.json();
        return sights;
    }

    /** 取得各年齡的 BMI 標準  */
    static async getBMIStandard() {
        const path = '/service/health/get_bmi_standard'
        const resp = await fetch(path)
        const wh = await resp.json();
        return wh;
    }

}

