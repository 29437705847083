import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import NotSigninPage from './hooks/not-signin-page';

import './App.css';
import Main from "./pages/Main";
import Root from "./components/Root";
import RouteErrorPage from "./components/RouteErrorPage";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: "main",
        element: <Main />,
      },
      {
        path: 'auth/not-signin',
        element: <NotSigninPage />
      }
    ]
  },
  
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
