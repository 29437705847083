import { useRef, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";
ChartJS.register(
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartDataLabels
);
export default function MultiLineChart({ labels, dataSet, sightSide }) {
  const [dataSet1, setDataSet1] = useState([]);
  const [dataSet2, setDataSet2] = useState([]);
  const testData1 = [
    "1.2",
    "1.0",
    "0.8",
    "0.7",
    "1.0",
    "0.9",
    "0.9",
    "0.7",
    "0.5",
    "0.5",
    "0.5",
    "0.4",
  ];
  const testData2 = [
    null,
    null,
    null,
    "1.2",
    null,
    "1.2",
    "1.1",
    "0.8",
    "0.5",
    null,
    "1.1",
    "0.4",
  ];
  const [minY, setMinY] = useState(0);
  useEffect(() => {
    if (dataSet[1]) {
      if (!sightSide) {
        // 左眼
        setDataSet1(dataSet[0]);
        setDataSet2(dataSet[1]);
      } else {
        // 右眼
        setDataSet1(dataSet[2]);
        setDataSet2(dataSet[3]);
      }
    } else {
      setDataSet1([]);
      setDataSet2([]);
    }
  }, [sightSide, dataSet, labels]);

  useEffect(() => {
    const minFrom = (dataSet) => {
      if (!Array.isArray(dataSet) || dataSet.length === 0) return undefined;
      const filteredValues = dataSet.filter((value) => value != null);
      if (filteredValues.length === 0) return undefined;
      return Math.min(...filteredValues.map(parseFloat));
    };
    const minFromDataSet1 = minFrom(dataSet1);
    const minFromDataSet2 = minFrom(dataSet2);
    if (minFromDataSet1 !== undefined && minFromDataSet2 !== undefined) {
      setMinY(Math.min(minFromDataSet1, minFromDataSet2) - 0.5);
    } else {
      setMinY((minFromDataSet1 ?? minFromDataSet2 ?? 0) - 0.1);
    }
  }, [dataSet1, dataSet2]);

  const chartRef = useRef(null);
  const bodyRef = useRef(null);
  const pointColorArr = ["#F2A296", "#FECB66"];
  const LineColorArr = ["#ADD6B8", "#CDB6DB"];
  const TextColorArr = ["#6A8A73", "#867591"];
  useEffect(() => {
    const totalLabels = labels.length;
    if (totalLabels > 4) {
      if (bodyRef?.current?.offsetWidth) {
        const nowWidth = bodyRef.current.offsetWidth;
        const newWidth = nowWidth + (totalLabels - 4) * 50;
        bodyRef.current.style.width = `${nowWidth}px`;
        chartRef.current.style.width = `${newWidth}px`;
      }
    } else {
      chartRef.current.style.width = `auto`;
    }
  }, [labels.length]);
  const adjustAnchor = (value, dataSet1Value) => {
    // 檢查對應索引的值，較小則調整將數字顯示在下方
    return Math.abs(parseFloat(value) - parseFloat(dataSet1Value)) < 0.6
      ? "start"
      : "end";
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "矯正",
        data: dataSet2,
        spanGaps: true,
        borderColor: LineColorArr[1],
        pointBackgroundColor: pointColorArr[1],
        pointBorderColor: pointColorArr[1],
        datalabels: {
          color: TextColorArr[1],
          anchor: "end",
          align: "end",
          offset: 0,
        },
      },
      {
        label: "裸視", // 資料集標籤
        data: dataSet1, // 資料
        spanGaps: true, //跳過null值
        borderColor: LineColorArr[0], // 線條顏色
        pointBackgroundColor: pointColorArr[0], // 點的顏色
        pointBorderColor: pointColorArr[0], // 點的邊框顏色
        datalabels: {
          color: TextColorArr[0], // datalabels的顏色
          anchor: function (context) {
            const index = context.dataIndex;
            const value = context.dataset.data[index];
            const dataSet1Value = dataSet2[index];
            return adjustAnchor(value, dataSet1Value);
          },
          align: function (context) {
            const index = context.dataIndex;
            const value = context.dataset.data[index];
            const dataSet1Value = dataSet2[index];
            return adjustAnchor(value, dataSet1Value);
          },
          offset: 0,
        },
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        top: 20,
        right: 30,
      },
    },
    elements: {
      point: {
        radius: 6,
        hoverRadius: 8,
        pointStyle: "rectRot",
      },
      line: {
        borderWidth: 3,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        min: minY,
        grid: {
          display: true,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#423928",
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      datalabels: {
        font: {
          size: 14,
        },
        clamp: true,
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="h-full grow overflow-auto scroll-box" ref={bodyRef}>
      <div className="h-full !min-w-full containerBody" ref={chartRef}>
        {/* <div
          className=" absolute btn btn-xs right-0 opacity-0 hover:opacity-100 "
          onClick={() => {
            setDataSet1(testData1);
            setDataSet2(testData2);
          }}
        >
          測
        </div> */}
        <Line data={data} options={options} id="lineChart" />
      </div>
    </div>
  );
}
