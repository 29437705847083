import { useRef, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";
ChartJS.register(
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartDataLabels
);
export default function LineChart({ labels, dataSet, colorArr = ["#F2A296"] }) {
  const chartRef = useRef(null);
  const bodyRef = useRef(null);

  // 偵測資料筆數動態更改圖表寬度
  useEffect(() => {
    const totalLabels = labels.length;
    if (totalLabels > 5) {
      if (bodyRef?.current?.offsetWidth) {
        const nowWidth = bodyRef.current.offsetWidth;
        const newWidth = nowWidth + (totalLabels - 5) * 60;
        bodyRef.current.style.width = `${nowWidth}px`;
        chartRef.current.style.width = `${newWidth}px`;
      }
    } else {
      chartRef.current.style.width = `auto`;
    }
  }, [labels.length]);
  const data = {
    labels: labels,
    datasets: [
      {
        data: dataSet,
      },
    ],
  };
  const options = {
    layout: {
      padding: {
        top: 30,
        right: 30,
      },
    },
    elements: {
      point: {
        radius: 8,
        hoverRadius: 10,
        pointStyle: "rectRot",
        backgroundColor: colorArr,
      },
      line: {
        borderColor: colorArr.length > 1 ? "#D6D3D1" : "#ADD6B8",
        borderWidth: 3,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          display: true,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#423928",
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      datalabels: {
        clamp: true,
        anchor: "end",
        color: "#423928",
        font: {
          size: 14,
        },
        offset: 1,
        align: "end",
        formatter: function (value, index, values) {
          if (value > 0) {
            return value;
          } else {
            value = "";
            return value;
          }
        },
      },
      legend: {
        display: false,
      },
    },
    // animation: false,
  };

  return (
    <div className="h-full !min-w-full overflow-auto scroll-box" ref={bodyRef}>
      <div className="h-full !min-w-full containerBody" ref={chartRef}>
        <Line data={data} options={options} id="lineChart" />
      </div>
    </div>
  );
}
