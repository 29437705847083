import React, { useEffect, useState, useRef } from "react";
import { useUserContext } from "../hooks/user-validation";
import { HealthHelper } from "../dal/health";
import "./Main.css";
import Card2 from "../components/Card2";
import { DevAPIJasmineHelper } from "../dal/devapi_jasmine";
import { UserHelper } from "../dal/user";
import ClassList from "../components/ClassList";
export default function Main() {
  const { userInfo } = useUserContext();
  // 前台顯示資料
  const [dataInfo, setDataInfo] = useState([
    { title: "身高", img: "fruit/fruit1s.svg", data: [], grade_semester: [] },
    { title: "體重", img: "fruit/fruit3s.svg", data: [], grade_semester: [] },
    { title: "BMI", img: "fruit/fruit2s.svg", data: [], grade_semester: [] },
    { title: "視力", img: "fruit/fruit4s.svg", data: [], grade_semester: [] },
    // {
    //   title: "立定跳遠",
    //   img: "fruit/fruit5s.svg",
    //   data: [],
    //   grade_semester: [],
    // },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  // 顯示的學生數據
  const [studData, setStudData] = useState({
    className: "777",
    seatNo: 7,
    name: "測試",
    gender: 0,
  });
  const [BMIList, setBMIList] = useState([]);
  // 教師的班級、學生資訊
  const [classInfo, setClassInfo] = useState(null);
  // 是否有得到目前使用者資訊
  const [hasUserInfo, setHasUerInfo] = useState(false);
  // 頭像
  const [avatarImg, setAvatarImg] = useState("fruit5s.svg");
  // 無學生顯示視窗
  const [showNoStudErr, setShowNoStudErr] = useState(false);

  // 錯誤資訊
  const [errInfo, setErrInfo] =
    useState("本系統僅提供班導師、家長、學生使用。");

  // 全校班級清單
  const [classList, setClassList] = useState([]);

  // 是否可以檢視班級清單
  const [ableShowClassList, setAbleShowClassList] = useState(false);

  // 當取得使用者和數據後，隨機生成頭像
  useEffect(() => {
    if (!isLoadingData && hasUserInfo) {
      setIsLoading(false);
      // 使用安全的隨機數生成器
      const array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      const randomNumber = (array[0] % 7) + 5; // 生成 5 到 11 之間的隨機整數
      setAvatarImg(`fruit${randomNumber}s.svg`);
    }
  }, [isLoadingData, hasUserInfo]);

  // 取得使用者資訊後，下一步處理(非老師用)
  useEffect(() => {
    if (userInfo) {
      console.log({ userInfo });
      // 檢查是否有查看全校數據權限
      if (userInfo.isDecisionMaker) {
        getAllStudData().then(() => {
          setAbleShowClassList(true);
          setHasUerInfo(true);
          setIsLoadingData(false);
        });
      } else if (userInfo.roleType === "teacher") {
        console.log("老師登入：", userInfo, "class:", userInfo.classes);
        if (userInfo.classes) {
          if (userInfo.classes.length > 0) {
            console.log("偵測到有班級", userInfo);
            getTeacherData();
            setHasUerInfo(true);
          } else {
            console.log("名下無班級", userInfo);
            setShowNoStudErr(true);
            setErrInfo("本系統僅提供班導師、家長、學生使用。");
          }
        }
      } else if (userInfo.roleType !== "teacher") {
        getStudInfo();
      }
    }
  }, [userInfo]);

  useEffect(() => {
    console.log({ dataInfo });
  }, [dataInfo]);

  /** 判讀使用者角色，取得學生資訊 */
  const getStudInfo = () => {
    if (userInfo.parent) {
      setStudData({
        className: userInfo.parent.className,
        seatNo: userInfo.parent.seatNo,
        name: userInfo.parent.studentName,
        gender: userInfo.parent.gender == "M" ? 1 : 2,
      });
      setHasUerInfo(true);
    } else if (userInfo.student) {
      setStudData({
        className: userInfo.student.className,
        seatNo: userInfo.student.seatNo,
        name: userInfo.student.studentName,
        gender: userInfo.student.gender == "M" ? 1 : 2,
      });
      setHasUerInfo(true);
    } else {
      console.log("名下無學生數據", userInfo);
      setShowNoStudErr(true);
      setErrInfo("本系統僅提供班導師、家長、學生使用。");
    }
  };

  // 取得學生資訊後，直接讀取資料(非老師用)
  useEffect(() => {
    if (
      studData.gender !== 0 &&
      isLoadingData &&
      userInfo.roleType !== "teacher"
    ) {
      getData();
    }
    console.log({ studData });
  }, [studData]);

  /** fetch學生資料 */
  const getData = async () => {
    try {
      if (!userInfo) {
        return;
      }
      // 使用 Promise.all 讓三個 await 同時進行
      const [wh, sights, bmi_list] = await Promise.all([
        HealthHelper.getMyWH(),
        HealthHelper.getMySights(),
        HealthHelper.getBMIStandard(),
      ]);
      console.log({ wh, sights, bmi_list });
      setBMIList(bmi_list);
      calcData(wh, sights, bmi_list, studData.gender);
    } catch (error) {
      // 在這裡處理錯誤
      console.error("發生錯誤:", error);
    }
  };

  /** fetch老師資料 */
  const getTeacherData = async () => {
    try {
      if (!userInfo || userInfo.classes.length < 1) {
        setShowNoStudErr(true);
        setErrInfo("本系統僅提供班導師、家長、學生使用。");
        return;
      }
      const classID = userInfo.classes[0].classID;
      // 使用 Promise.all 讓兩個非相依的請求同時進行
      const [classStudents] = await Promise.all([
        DevAPIJasmineHelper.getClassStudents(userInfo.schoolDsns, classID),
      ]);

      console.log({ action: "登入者的班級學生清單", classStudents });
      /** 判斷老師有沒有學生 & 預設讀取第一個學生 */
      if (classStudents?.[0]?.student?.length > 0) {
        setClassInfo(classStudents[0]);
        getFirstStud(classStudents[0].student[0].studentID, classStudents[0]);
      } else {
        setShowNoStudErr(true);
        setErrInfo("本系統僅提供班導師、家長、學生使用。");
      }
    } catch (error) {
      // 在這裡處理錯誤
      console.error("發生錯誤:", error);
      setShowNoStudErr(true);
      setErrInfo("本系統僅提供班導師、家長、學生使用。");
    }
  };
  /** 讀取第一個學生*/
  const getFirstStud = async (studentID, classList) => {
    try {
      setIsLoadingData(true);
      const targetStudent = classList.student.find(
        (student) => student.studentID == studentID
      );

      // 使用 Promise.all 讓三個非相依的請求同時進行
      const [gender, wh, sights, bmi_list] = await Promise.all([
        UserHelper.getGender(targetStudent.idNumberHash),
        HealthHelper.getStudentWH(targetStudent.idNumberHash),
        HealthHelper.getStudentSights(targetStudent.idNumberHash),
        HealthHelper.getBMIStandard(),
      ]);
      const genderNo = gender.gender === "M" ? 1 : 2;
      setStudData({
        className: classList.className,
        seatNo: targetStudent.seatNo,
        name: targetStudent.studentName,
        gender: genderNo,
      });
      setBMIList(bmi_list);
      calcData(wh, sights, bmi_list, genderNo);
    } catch (error) {
      // 在這裡處理錯誤
      console.error("發生錯誤:", error);
    }
  };

  /** fetch全校學生資料 */
  const getAllStudData = async () => {
    try {
      const classList = await DevAPIJasmineHelper.getClasses(
        userInfo.schoolDsns
      );
      console.log({ action: "全校學生清單", classList });
      setClassList(classList);
    } catch (error) {
      // 在這裡處理錯誤
      console.error("發生錯誤:", error);
      setShowNoStudErr(true);
      setErrInfo("本系統僅提供班導師、家長、學生使用。");
    }
  };

  const handleSelectedClass = async (classID) => {
    setIsLoadingData(true);
    const classInfo = classList.find(
      (classInfo) => classInfo.classID === classID
    );
    if (!classInfo) {
      setShowNoStudErr(true);
      setErrInfo("該班級無學生資料。");
      return;
    }
    const [classStudents] = await Promise.all([
      DevAPIJasmineHelper.getClassStudents(userInfo.schoolDsns, classID),
    ]);
    console.log("該班級資訊", classStudents);
    if (classStudents?.[0]?.student?.length > 0) {
      const students = classStudents[0].student;
      console.log("學生清單", students);
      setClassInfo({ ...classInfo, student: students });
      getFirstStud(classStudents[0].student[0].studentID, classStudents[0]);
      colAllReset();
    } else {
      setShowNoStudErr(true);
      setErrInfo("該班級無學生資料。");
    }
  };

  /** 組合年級+學期 */
  const getGradeSemesterDesc = (grade, semester) => {
    if (grade !== undefined && grade !== null) {
      const chineseNumerals = [
        { grade: -4, title: "幼" },
        { grade: -3, title: "小" },
        { grade: -2, title: "中" },
        { grade: -1, title: "大" },
        { grade: 1, title: "一" },
        { grade: 2, title: "二" },
        { grade: 3, title: "三" },
        { grade: 4, title: "四" },
        { grade: 5, title: "五" },
        { grade: 6, title: "六" },
        { grade: 7, title: "七" },
        { grade: 8, title: "八" },
        { grade: 9, title: "九" },
        { grade: 10, title: "十" },
        { grade: 11, title: "十一" },
        { grade: 12, title: "十二" },
      ];
      const chineseSemesters = [
        { semester: 1, title: "上" },
        { semester: 2, title: "下" },
      ];

      // 查找匹配的年級描述
      const gradeDescObj = chineseNumerals.find((item) => item.grade === grade);
      // 查找匹配的學期描述
      const semesterDescObj = chineseSemesters.find(
        (item) => item.semester === semester
      );

      const gradeDesc = gradeDescObj ? gradeDescObj.title : "未知";
      const semesterDesc = semesterDescObj ? semesterDescObj.title : "";

      return `${gradeDesc}${semesterDesc}`;
    }

    return "未知";
  };
  function calculateBmi(weight, height) {
    if (!weight || !height) return null;
    const bmiResult =
      Math.round((weight / Math.pow(height / 100, 2)) * 10) / 10;
    return bmiResult;
  }
  function calcAge(grade_year, semester) {
    // 國小一年級以上+5計算，幼稚園+6計算
    let age = grade_year >= 1 ? grade_year + 5 : grade_year + 6;
    if (semester === 2) {
      age += 0.5;
    }
    return age;
  }
  const getBMIStatus = (bmi, bmi_list, gender, age) => {
    const filteredData = bmi_list.filter(
      (item) => item.gender === gender && item.age === age
    )[0];
    if (filteredData) {
      if (bmi < filteredData.bmi_underweight) {
        return "過輕";
      } else if (
        bmi >= filteredData.bmi_underweight &&
        bmi < filteredData.bmi_overweight
      ) {
        return "正常";
      } else if (
        bmi >= filteredData.bmi_overweight &&
        bmi < filteredData.bmi_fat
      ) {
        return "過重";
      } else if (bmi >= filteredData.bmi_fat) {
        return "肥胖";
      }
    } else {
      return "未定";
    }
  };
  const processWhData = (wh, bmi_list, gender) => {
    let bmiData = [];
    let heightData = [];
    let weightData = [];
    let bmiGradeSemester = [];
    let heightGradeSemester = [];
    let weightGradeSemester = [];

    wh.forEach((item) => {
      if (item.weight !== null && item.height !== null) {
        const grade_semester = getGradeSemesterDesc(
          item.grade_year,
          item.semester
        );
        const age = calcAge(item.grade_year, item.semester);
        const bmi = calculateBmi(item.weight, item.height);

        bmiData.push({
          bmi: bmi,
          info: getBMIStatus(bmi, bmi_list, gender, age),
        });
        bmiGradeSemester.push(grade_semester);
      }
      if (item.weight !== null) {
        weightData.push(item.weight);
        const grade_semester = getGradeSemesterDesc(
          item.grade_year,
          item.semester
        );
        weightGradeSemester.push(grade_semester);
      }
      if (item.height !== null) {
        heightData.push(item.height);
        const grade_semester = getGradeSemesterDesc(
          item.grade_year,
          item.semester
        );
        heightGradeSemester.push(grade_semester);
      }
    });

    return {
      bmiData,
      heightData,
      weightData,
      bmiGradeSemester,
      heightGradeSemester,
      weightGradeSemester,
    };
  };

  const processSightsData = (sights) => {
    let sightsData = [];
    let sightsGradeSemester = [];

    sights.forEach((item) => {
      if (
        item.sight0_l !== null ||
        item.sight0_r !== null ||
        item.sight_l !== null ||
        item.sight_r !== null
      ) {
        sightsData.push({
          ...item,
          grade_semester: getGradeSemesterDesc(item.grade_year, item.semester),
        });
        sightsGradeSemester.push(
          getGradeSemesterDesc(item.grade_year, item.semester)
        );
      }
    });

    return { sightsData, sightsGradeSemester };
  };

  const calcData = (wh, sights, bmi_list, gender) => {
    if (!wh || !sights) {
      console.log("wh 或 sights 為空或不存在");
      setDataInfo((prevData) => {
        const newData = [...prevData].map((item) => ({
          data: [],
          grade_semester: [],
        }));
        return newData;
      });
      setIsLoadingData(false);
      return;
    }

    const {
      bmiData,
      heightData,
      weightData,
      bmiGradeSemester,
      heightGradeSemester,
      weightGradeSemester,
    } = processWhData(wh, bmi_list, gender);

    const { sightsData, sightsGradeSemester } = processSightsData(sights);

    setDataInfo((prevData) => {
      const newData = [...prevData];
      newData[0].data = heightData;
      newData[0].grade_semester = heightGradeSemester;
      newData[1].data = weightData;
      newData[1].grade_semester = weightGradeSemester;
      newData[2].data = bmiData;
      newData[2].grade_semester = bmiGradeSemester;
      newData[3].data = sightsData;
      newData[3].grade_semester = sightsGradeSemester;
      return newData;
    });

    setIsLoadingData(false);
  };

  const colData = [
    "relative col-start-1 col-span-11 -rotate-12",
    "relative col-start-2 col-span-11 -rotate-6",
    "relative col-start-2 col-span-11 rotate-6",
    "relative col-start-1 col-span-11 rotate-12",
    "relative col-start-2 col-span-11 -rotate-6 transform -translate-y-5",
    "relative col-start-2 col-span-11 rotate-6 transform -translate-y-24",
    "relative col-start-2 col-span-11 rotate-6 transform translate-y-4",
    "relative col-start-1 col-span-11 -rotate-12 -translate-y-12",
    "relative col-start-2 col-span-11 -rotate-6 transform -translate-y-16",
    "relative col-start-1 col-span-11 rotate-12 -translate-y-11",
    "relative col-start-2 col-span-11 -rotate-6 transform -translate-y-20",
  ];
  const [nowIndex, setNowIndex] = useState(0);
  const [nowData, setNowData] = useState(dataInfo.slice(0, 4));

  /**切換顯示資料 */
  useEffect(() => {
    setNowData(dataInfo.slice(nowIndex, nowIndex + 4));
  }, [nowIndex]);

  /** 上下滑後切換資料 */
  const handleWheel = (e) => {
    if (dataInfo.length < 5) {
      return;
    }
    // 檢查滾輪方向，e.deltaY > 0 表示向下滾動，e.deltaY < 0 表示向上滾動
    colAllReset();
    if (e.deltaY > 0) {
      setNowIndex((prevIndex) =>
        prevIndex < dataInfo.length - 4 ? prevIndex + 1 : prevIndex
      );
    } else if (e.deltaY < 0) {
      setNowIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    }
  };
  const coll0Ref = useRef(null);
  const coll1Ref = useRef(null);
  const coll2Ref = useRef(null);
  const coll3Ref = useRef(null);
  const card0Ref = useRef(null);
  const card1Ref = useRef(null);
  const card2Ref = useRef(null);
  const card3Ref = useRef(null);
  const collArr = [coll0Ref, coll1Ref, coll2Ref, coll3Ref];

  const colReset = () => {
    card0Ref.current.className = colData[0];
    card1Ref.current.className = colData[1];
    card2Ref.current.className = colData[2];
    card3Ref.current.className = colData[3];
  };

  const colAllReset = () => {
    colReset();
    collArr.forEach((ref) => {
      ref.current.className = "myCollapse";
    });
    setNowIndex(0);
  };

  const changeColCss = (index) => {
    switch (index) {
      case 0:
        card1Ref.current.className = colData[6];
        card2Ref.current.className = colData[3];
        card3Ref.current.className = "hidden";
        break;
      case 1:
        card0Ref.current.className = colData[0];
        card1Ref.current.className = colData[4];
        card2Ref.current.className = colData[3];
        card3Ref.current.className = "hidden";
        break;
      case 2:
        card0Ref.current.className = "h-0 w-0 overflow-hidden opacity-0";
        card1Ref.current.className = colData[7];
        card2Ref.current.className = colData[8];
        card3Ref.current.className = colData[9];
        break;
      case 3:
        // 如果已經是最末組，則打開最後一張
        card0Ref.current.className = "h-0 w-0 overflow-hidden opacity-0";
        if (window.innerHeight < 600) {
          // 視窗太小，隱藏第二張
          card1Ref.current.className = "h-0 w-0 overflow-hidden opacity-0";
          card2Ref.current.className = colData[7];
          card3Ref.current.className = colData[8];
        } else {
          card1Ref.current.className = colData[7];
          card2Ref.current.className = colData[10];
          card3Ref.current.className = colData[5];
        }
        break;

      default:
        break;
    }
  };
  const collRefChange = (ref, toOpen) => {
    if (toOpen) {
      ref.current.className = `myCollapse active`;
    } else {
      ref.current.className = "myCollapse";
    }
  };
  const openLastCard = (index) => {
    if (nowIndex < dataInfo.length - 4) {
      // 切換到下一組數據
      setNowIndex((prevIndex) => prevIndex + 1);
      if (!coll2Ref.current.classList.contains("active")) {
        setTimeout(() => {
          console.log("changeNowIndex");
          handleOpen(2);
        }, 40);
      }
    } else {
      // 如果已經是最末組，則打開最後一張
      card3Ref.current.className = colData[5];
      collRefChange(collArr[index], true);
      collArr.forEach((ref, i) => {
        if (i !== index) {
          collRefChange(ref, false);
          changeColCss(index);
        }
      });
    }
  };
  /** 卡片點擊事件 */
  const handleOpen = (index) => {
    if (!collArr[index].current.classList.contains("active")) {
      //打開卡片
      if (index === 3) {
        openLastCard(index);
      } else {
        collRefChange(collArr[index], true);
        collArr.forEach((ref, i) => {
          if (i !== index) {
            collRefChange(ref, false);
            changeColCss(index);
          }
        });
      }
    } else {
      //關閉卡片
      collRefChange(collArr[index], false);
      colReset();
    }
  };

  /** 送出選定學生處理 */
  const handleSearchStud = async (studentID) => {
    try {
      setIsLoadingData(true);
      colAllReset();
      const targetStudent = classInfo.student.find(
        (student) => student.studentID == studentID
      );

      // 使用 Promise.all 讓三個非相依的請求同時進行
      const [gender, wh, sights] = await Promise.all([
        UserHelper.getGender(targetStudent.idNumberHash),
        HealthHelper.getStudentWH(targetStudent.idNumberHash),
        HealthHelper.getStudentSights(targetStudent.idNumberHash),
      ]);
      const genderNo = gender.gender === "M" ? 1 : 2;
      setStudData({
        className: classInfo.className,
        seatNo: targetStudent.seatNo,
        name: targetStudent.studentName,
        gender: genderNo,
      });

      calcData(wh, sights, BMIList, genderNo);
    } catch (error) {
      // 在這裡處理錯誤
      console.error("發生錯誤:", error);
    }
  };
  const screenRef = useRef(null);
  useEffect(() => {
    const setDivHeight = () => {
      if (screenRef.current) {
        if (window.innerHeight > 500) {
          screenRef.current.style.height = `${window.innerHeight}px`;
        } else {
          screenRef.current.style.height = `500px`;
        }
      }
    };

    // 初始化時設定一次高度
    setDivHeight();

    // 5秒後再調試一次
    setTimeout(() => {
      setDivHeight();
    }, 5000);

    // 當視窗大小改變時重新設定高度
    const handleResize = () => {
      setDivHeight();
    };

    // 註冊事件監聽器
    window.addEventListener("resize", handleResize);

    // 在組件卸載時移除事件監聽器
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="flex justify-center overflow-auto max-w-screen bg-white"
        data-theme="light"
        ref={screenRef}
      >
        <div className="flex gap-4 items-center justify-center h-full overflow-hidden w-full relative outsideContainer">
          {/* 學生選擇器 */}

          {isLoading ? (
            <div
              className={`bg-main h-full w-full flex justify-center items-center`}
            >
              <span className="loading loading-dots loading-lg"></span>
              {showNoStudErr && (
                <div className="absolute text-lg w-72 p-6 bg-white border-2 border-stone-700 rounded-2xl">
                  {errInfo}
                </div>
              )}
            </div>
          ) : (
            <div className="w-full h-full mainContainer flex flex-col overflow-hidden">
              {ableShowClassList && (
                <ClassList
                  handleSelectedClass={handleSelectedClass}
                  classList={classList}
                  classInfo={classInfo}
                />
              )}
              <div className="pt-4 pb-4 px-[30px] shrink-0">
                <div className="flex gap-3 items-center ">
                  <div className="avatar">
                    <div className=" w-14 h-14 mask mask-squircle bg-main">
                      <img src={`/images/fruit/${avatarImg}`} />
                    </div>
                  </div>
                  {/* 學生選擇器 */}
                  {userInfo &&
                  userInfo.roleType === "teacher" &&
                  classInfo !== null ? (
                    <div className="z-[2] flex flex-wrap items-center gap-y-1 gap-x-2 overflow-hidden p-1">
                      <p className=" text-stone-700 font-medium">
                        {studData.className}
                      </p>
                      <select
                        className="select select-sm select-bordered w-fit max-w-full appSelect"
                        value={studData.studentID}
                        onChange={(e) => handleSearchStud(e.target.value)}
                      >
                        {classInfo?.student?.map((stud) => {
                          const formattedSeatNo = stud.seatNo
                            ? String(stud.seatNo).padStart(2, "0")
                            : "";
                          return (
                            <option
                              value={stud.studentID}
                              key={stud.studentID}
                            >{`${formattedSeatNo} ${stud.studentName}`}</option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    <div className="text-stone-700">
                      <h2 className="">
                        {studData.className +
                          " " +
                          studData.seatNo.toString().padStart(2, "0") +
                          "號"}
                      </h2>
                      <h2 className="">{studData.name}</h2>
                    </div>
                  )}
                </div>
              </div>
              {/* 背景圈圈 */}
              <div className="absolute h-full flex flex-col w-full max-w-[480px]">
                <div className="pt-[5rem] grow grid items-center w-full h-full">
                  <div className=" relative h-full min-h-[calc(500px-5rem)] overflow-visible">
                    <div className="circle " />
                  </div>
                </div>
              </div>
              <div className="relative flex grow items-center px-[30px]">
                <div
                  className="grid grid-cols-12 gap-y-12 w-full"
                  onWheel={handleWheel}
                >
                  <div className={colData[0]} ref={card0Ref}>
                    <Card2
                      item={nowData[0]}
                      nowIndex={nowIndex}
                      index={0}
                      handleOpen={handleOpen}
                      collRef={coll0Ref}
                    />
                  </div>
                  <div className={colData[1]} ref={card1Ref}>
                    <Card2
                      item={nowData[1]}
                      nowIndex={nowIndex}
                      index={1}
                      handleOpen={handleOpen}
                      collRef={coll1Ref}
                    />
                  </div>
                  <div className={colData[2]} ref={card2Ref}>
                    <Card2
                      item={nowData[2]}
                      nowIndex={nowIndex}
                      index={2}
                      handleOpen={handleOpen}
                      collRef={coll2Ref}
                    />
                  </div>
                  <div className={colData[3]} ref={card3Ref}>
                    <Card2
                      item={nowData[3]}
                      nowIndex={nowIndex}
                      index={3}
                      handleOpen={handleOpen}
                      collRef={coll3Ref}
                    />
                  </div>
                </div>
              </div>
              {/* Loading圖 */}
              <div
                className={`absolute z-[4] h-full flex flex-col w-full max-w-[480px] bg-green ${
                  !isLoadingData && "scale-out-hor-left"
                }`}
              ></div>
              <div
                className={`absolute z-[5] h-full flex justify-center items-center w-full max-w-[480px] bg-main ${
                  !isLoadingData && "scale-out-hor-left1"
                }`}
              >
                <span className="loading loading-dots loading-lg"></span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
