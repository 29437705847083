import React from "react";
import { Outlet } from "react-router-dom";
import UserValidation from '../hooks/user-validation'

/** 最上層頁面 */
export default function Root() {

  return (
    <UserValidation>
      <div id="detail">
        <Outlet /> {/* child router 內容顯示處 */}
      </div>
    </UserValidation>
  );
}
